import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import SideMenu from "./SideMenu";

const ProtectedRoute = () => {
  // Verifica se o ambiente é de desenvolvimento
  const isDevelopment = process.env.NODE_ENV === "development";
  const isAuthenticated = Boolean(localStorage.getItem("authToken"));

  return isAuthenticated || isDevelopment ? (
    <>
      <SideMenu />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
