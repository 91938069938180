import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import OrdersList from "./components/OrdersList";
import UsersList from "./components/UsersList";
import DigitalMenu from "./components/DigitalMenu";
import SideMenu from "./components/SideMenu";
import ProtectedRoute from "./components/ProtectedRoute";
import Delivery from "./components/Delivery"; // Importe o novo componente de Delivery

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Rota pública para o sistema de delivery */}
          <Route path="/" element={<Delivery />} />

          {/* Rota de login */}
          <Route path="/login" element={<LoginPage />} />

          {/* Rotas protegidas */}
          <Route path="/admin" element={<ProtectedRoute />}>
            <Route path="/admin/orders" element={<OrdersList />} />
            <Route path="/admin/users" element={<UsersList />} />
            <Route path="/admin/menu" element={<DigitalMenu />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
