import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import api from "../services/api";

const DigitalMenu = () => {
  const [menu, setMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await api.get("/menu");

        if (response.data.items) {
          setMenu(response.data);
        } else {
          console.error("Erro: Estrutura inesperada no menu");
        }
      } catch (error) {
        console.error("Erro ao buscar o cardápio", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMenu();
  }, []);

  const handleProductChange = (itemIndex, productIndex, field, value) => {
    const updatedMenu = { ...menu };

    if (field === "price") {
      updatedMenu.items[itemIndex].products[productIndex][field] =
        parseFloat(value) || 0;
    } else {
      updatedMenu.items[itemIndex].products[productIndex][field] = value;
    }

    setMenu(updatedMenu);
  };

  const handleSave = async () => {
    try {
      await api.put("/menu", menu);
      alert("Cardápio salvo com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar o cardápio", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredMenu = menu
    ? {
        ...menu,
        items: menu.items.filter((item) =>
          item.products.some((product) =>
            product.dishName.toLowerCase().includes(searchTerm)
          )
        ),
      }
    : null;

  if (isLoading) {
    return <Typography>Carregando o cardápio...</Typography>;
  }

  return (
    <Box
      sx={{
        marginLeft: "240px",
        padding: "20px",
        width: "calc(100% - 240px)",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2, color: "#5d0707" }}>
        Editar Cardápio
      </Typography>

      <TextField
        label="Buscar Prato"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
        sx={{ marginBottom: 2 }}
      />

      {filteredMenu.items.map((item, itemIndex) => (
        <Accordion key={itemIndex} sx={{ marginBottom: 3 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#5d0707" }} />}
          >
            <Typography variant="h6" sx={{ color: "#5d0707" }}>
              {item.type}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item.products.map((product, productIndex) => (
              <Box key={productIndex} sx={{ marginBottom: 2 }}>
                <TextField
                  label="Nome do Prato"
                  value={product.dishName}
                  onChange={(e) =>
                    handleProductChange(
                      itemIndex,
                      productIndex,
                      "dishName",
                      e.target.value
                    )
                  }
                  fullWidth
                  sx={{ marginBottom: 1 }}
                />
                <TextField
                  label="Descrição"
                  value={product.descriptionDish}
                  onChange={(e) =>
                    handleProductChange(
                      itemIndex,
                      productIndex,
                      "descriptionDish",
                      e.target.value
                    )
                  }
                  fullWidth
                  sx={{ marginBottom: 1 }}
                />
                <TextField
                  label="Preço"
                  value={product.price}
                  onChange={(e) =>
                    handleProductChange(
                      itemIndex,
                      productIndex,
                      "price",
                      e.target.value
                    )
                  }
                  fullWidth
                  sx={{ marginBottom: 2 }}
                />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}

      <Button
        variant="contained"
        sx={{ backgroundColor: "#5d0707" }}
        onClick={handleSave}
      >
        Salvar Alterações
      </Button>
    </Box>
  );
};

export default DigitalMenu;
