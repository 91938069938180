import React from "react";
import { Drawer, List, ListItem, ListItemText, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const SideMenu = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  return (
    <Drawer variant="permanent">
      <List>
        <ListItem
          button
          component={Link}
          to="/admin/orders"
          sx={{
            backgroundColor: "#fff", // Fundo branco
            "&:hover": {
              backgroundColor: "#f5f5f5", // Cor ao passar o mouse
            },
          }}
        >
          <ListItemText
            primary="Pedidos"
            sx={{
              color: "#5d0707", // Cor da fonte
              fontWeight: "bold", // Estilo negrito para maior destaque
            }}
          />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/admin/users"
          sx={{
            backgroundColor: "#fff", // Fundo branco
            "&:hover": {
              backgroundColor: "#f5f5f5", // Cor ao passar o mouse
            },
          }}
        ></ListItem>
        <ListItem button component={Link} to="/admin/menu">
          <ListItemText
            primary="Cardápio Digital"
            sx={{
              color: "#5d0707", // Cor da fonte
              fontWeight: "bold", // Estilo negrito para maior destaque
            }}
          />
        </ListItem>

        <ListItem>
          <Button
            variant="contained"
            onClick={handleLogout}
            sx={{
              backgroundColor: "#5d0707", // Cor de fundo do botão de logout
              color: "#fff", // Cor da fonte do botão
              "&:hover": {
                backgroundColor: "#7a0a0a", // Cor ao passar o mouse
              },
            }}
          >
            Logout
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideMenu;
