import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Badge,
  Modal,
  TextField,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import InputMask from "react-input-mask";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import api from "../services/api";
import ModalDelivery from "./ModalDelivery";

const DELIVERY_TAX = 5.0; // Valor da entrega

const Delivery = () => {
  const [menu, setMenu] = useState(null); // Para armazenar os itens do menu vindos do backend
  const [cart, setCart] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState(1); // Gerenciar etapas do modal
  const [isOpenForOrders, setIsOpenForOrders] = useState(true); // Verificar se está no horário de atendimento
  const [deliveryOption, setDeliveryOption] = useState("Entrega"); // "Entrega" ou "Retirada"
  const [customerData, setCustomerData] = useState({
    name: "",
    phone: "",
    address: "",
    paymentMethod: "",
    change: "",
  });

  const [showSnackbar, setShowSnackbar] = useState(false); // Controle do Snackbar
  const [cartHighlight, setCartHighlight] = useState(false); // Controle do destaque no ícone
  const [isSubmitting, setIsSubmitting] = useState(false); // Controle do estado de envio

  // Função para validar os dados do cliente
  const validateCustomerData = () => {
    const { name, phone, address, paymentMethod } = customerData;
    if (!name || !phone || !paymentMethod) return false;
    if (deliveryOption === "Entrega" && !address) return false;
    return true;
  };

  // Função para verificar se está dentro do horário de atendimento
  const checkOpeningHours = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 8 && currentHour < 16) {
      setIsOpenForOrders(true);
    } else {
      setIsOpenForOrders(false);
    }
  };

  // Função para buscar os itens do cardápio do backend
  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await api.get("/menu");
        console.log("Dados recebidos: ", response.data);

        const updatedMenu = { ...response.data };
        updatedMenu.items = updatedMenu.items.map((category) => {
          return {
            ...category,
            products: category.products.map((product) => ({
              ...product,
              price: parseFloat(product.price),
            })),
          };
        });

        setMenu(updatedMenu);
      } catch (error) {
        console.error("Erro ao buscar o cardápio", error);
      }
    };

    fetchMenu();
    checkOpeningHours(); // Verifica o horário de funcionamento ao carregar a página
  }, []);

  const addToCart = (product) => {
    setCart([...cart, product]);
    setShowSnackbar(true); // Exibir Snackbar
    setCartHighlight(true); // Destacar ícone do carrinho
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const removeFromCart = (indexToRemove) => {
    setCart(cart.filter((_, index) => index !== indexToRemove));
  };

  const calculateCartTotal = () => {
    return cart.reduce((total, product) => total + product.price, 0);
  };

  // Função para calcular o total com ou sem a taxa de entrega, dependendo da opção de entrega
  const calculateTotalWithDelivery = () => {
    return (
      calculateCartTotal() + (deliveryOption === "Entrega" ? DELIVERY_TAX : 0)
    );
  };

  const copyPixKey = () => {
    navigator.clipboard.writeText("28.797.532.0001/10");
    alert("Chave Pix copiada!");
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    setStep(1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({
      ...customerData,
      [name]: value,
    });
  };

  const handleDeliveryOptionChange = (e) => {
    setDeliveryOption(e.target.value);
  };

  const proceedToCustomerInfo = () => {
    setStep(2);
  };

  const formatWhatsAppMessage = () => {
    const formattedItems = cart
      .map((item) => `*${item.dishName}* - R$ ${item.price.toFixed(2)}`)
      .join("\n------------------\n");
    const deliveryInfo =
      deliveryOption === "Entrega"
        ? `Total com entrega: R$ ${calculateTotalWithDelivery().toFixed(
            2
          )}\nTaxa de entrega: R$ ${DELIVERY_TAX.toFixed(
            2
          )}\n------------------`
        : `Total sem taxa de entrega: R$ ${calculateCartTotal().toFixed(
            2
          )}\n------------------`;
    const clientInfo = `Nome: ${customerData.name}\nTelefone: ${
      customerData.phone
    }\n${
      deliveryOption === "Entrega" ? `Endereço: ${customerData.address}\n` : ""
    }Forma de Pagamento: ${customerData.paymentMethod}${
      customerData.paymentMethod === "Dinheiro"
        ? `\nTroco para: R$ ${customerData.change}`
        : ""
    }`;
    return `Olá, gostaria de fazer um pedido:\n\n${formattedItems}\n------------------\n${deliveryInfo}\n${clientInfo}`;
  };

  // Modifique handleOrderSubmission para incluir a validação
  const handleOrderSubmission = async () => {
    if (!validateCustomerData()) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    try {
      setIsSubmitting(true);
      const formattedMessage = formatWhatsAppMessage();
      const whatsappURL = `https://wa.me/${
        menu.whatsapp
      }?text=${encodeURIComponent(formattedMessage)}`;
      window.open(whatsappURL, "_blank");

      const response = await api.post(
        "/orders",
        {
          name: customerData.name,
          phone: customerData.phone,
          customerId: customerData.phone,
          items: cart,
          totalPrice: calculateTotalWithDelivery(),
          paymentMethod: customerData.paymentMethod,
          deliveryAddress:
            deliveryOption === "Entrega" ? customerData.address : "Retirada",
          change: customerData.change,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 201) {
        alert("Pedido enviado com sucesso!");
        setCart([]);
        setModalOpen(false);
        setCustomerData({
          name: "",
          phone: "",
          address: "",
          paymentMethod: "",
          change: "",
        });
      } else {
        throw new Error("Erro ao salvar o pedido");
      }
    } catch (error) {
      console.error("Erro ao salvar o pedido:", error);
      alert("Não foi possível enviar o pedido. Tente novamente.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!menu) {
    return <Typography>Carregando o cardápio...</Typography>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ color: "#5d0707" }}>
          {menu.companyName}
        </Typography>
      </Box>

      <Typography variant="h6" sx={{ color: "#5d0707" }}>
        Endereço: {menu.address}
      </Typography>

      {/* Verifica se está no horário de atendimento */}
      {!isOpenForOrders ? (
        <Typography
          variant="h5"
          sx={{ color: "#d32f2f", textAlign: "center", marginTop: 4 }}
        >
          Estamos fechados no momento. Nosso horário de atendimento é das 08:00
          às 16:00.
        </Typography>
      ) : (
        <>
          <IconButton
            onClick={toggleModal}
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              backgroundColor: "#fff",
              zIndex: 1000,
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              transform: cartHighlight ? "scale(1.2)" : "scale(1)",
              transition: "transform 0.3s ease",
              width: "60px",
              height: "60px",
            }}
          >
            <Badge badgeContent={cart.length} color="secondary">
              <ShoppingCartIcon sx={{ color: "#5d0707", fontSize: 40 }} />
            </Badge>
          </IconButton>

          {/* Listar as categorias e produtos */}
          {menu.items.map((category, index) => (
            <Accordion
              key={index}
              sx={{ marginTop: 4, borderColor: "#5d0707" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#5d0707" }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h5" sx={{ color: "#5d0707" }}>
                  {category.type}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {category.products.map((product, i) => (
                    <Grid item xs={12} md={4} key={i}>
                      <Card sx={{ borderColor: "#5d0707" }}>
                        <CardContent>
                          <Typography variant="h6" sx={{ color: "#5d0707" }}>
                            {product.dishName}
                          </Typography>
                          <Typography variant="body2">
                            {product.descriptionDish}
                          </Typography>
                          <Typography variant="body2">
                            Preço: R${" "}
                            {typeof product.price === "number" &&
                            !isNaN(product.price)
                              ? product.price.toFixed(2)
                              : "Preço indisponível"}
                          </Typography>
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "#5d0707", marginTop: 2 }}
                            onClick={() => addToCart(product)}
                          >
                            Adicionar ao Carrinho
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}

          {/* Modal com os itens do carrinho e segunda etapa */}
          <Modal open={isModalOpen} onClose={toggleModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", md: 400 },
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 8, right: 8 }}
                onClick={toggleModal}
              >
                <CloseIcon />
              </IconButton>

              {step === 1 ? (
                <>
                  <Typography
                    variant="h5"
                    sx={{ color: "#5d0707", marginBottom: 2 }}
                  >
                    Carrinho de Compras
                  </Typography>

                  {cart.length === 0 ? (
                    <Typography variant="body1">
                      Carrinho está vazio.
                    </Typography>
                  ) : (
                    <Box>
                      {cart.map((item, index) => (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          key={index}
                        >
                          <Typography variant="body1">
                            {item.dishName} - R$ {item.price.toFixed(2)}
                          </Typography>
                          <IconButton onClick={() => removeFromCart(index)}>
                            <DeleteIcon sx={{ color: "#5d0707" }} />
                          </IconButton>
                        </Box>
                      ))}
                      <Typography variant="h6" sx={{ marginTop: 2 }}>
                        Subtotal: R$ {calculateCartTotal().toFixed(2)}
                      </Typography>
                      {deliveryOption === "Entrega" && (
                        <Typography variant="h6" sx={{ marginTop: 2 }}>
                          Taxa de Entrega: R$ {DELIVERY_TAX.toFixed(2)}
                        </Typography>
                      )}
                      <Typography variant="h6" sx={{ marginTop: 2 }}>
                        Total com {deliveryOption}: R${" "}
                        {calculateTotalWithDelivery().toFixed(2)}
                      </Typography>

                      {/* Opção entre entrega e retirada */}
                      <TextField
                        label="Opção de Entrega"
                        fullWidth
                        margin="normal"
                        select
                        value={deliveryOption}
                        onChange={handleDeliveryOptionChange}
                      >
                        <MenuItem value="Entrega">Entrega</MenuItem>
                        <MenuItem value="Retirada">Retirada</MenuItem>
                      </TextField>

                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#5d0707", marginTop: 2 }}
                        onClick={proceedToCustomerInfo}
                      >
                        Prosseguir para Dados do Cliente
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    sx={{ color: "#5d0707", marginBottom: 2 }}
                  >
                    Preencha seus dados
                  </Typography>

                  <TextField
                    label="Nome"
                    fullWidth
                    margin="normal"
                    name="name"
                    value={customerData.name}
                    onChange={handleInputChange}
                  />
                  <InputMask
                    mask="(99) 99999-9999"
                    value={customerData.phone}
                    onChange={handleInputChange}
                  >
                    {() => (
                      <TextField
                        label="Telefone"
                        fullWidth
                        margin="normal"
                        name="phone"
                      />
                    )}
                  </InputMask>

                  {/* Campo de endereço será exibido apenas se a opção for "Entrega" */}
                  {deliveryOption === "Entrega" && (
                    <TextField
                      label="Endereço Completo"
                      fullWidth
                      margin="normal"
                      name="address"
                      value={customerData.address}
                      onChange={handleInputChange}
                    />
                  )}

                  <TextField
                    label="Forma de Pagamento"
                    fullWidth
                    margin="normal"
                    select
                    name="paymentMethod"
                    value={customerData.paymentMethod}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Dinheiro">Dinheiro</MenuItem>
                    <MenuItem value="Crédito">Crédito</MenuItem>
                    <MenuItem value="Débito">Débito</MenuItem>
                    <MenuItem value="Pix">Pix</MenuItem>
                  </TextField>

                  {customerData.paymentMethod === "Dinheiro" && (
                    <TextField
                      label="Precisa de troco para quanto?"
                      fullWidth
                      margin="normal"
                      name="change"
                      value={customerData.change}
                      onChange={handleInputChange}
                    />
                  )}

                  {customerData.paymentMethod === "Pix" && (
                    <>
                      <Typography variant="body2" sx={{ marginTop: 2 }}>
                        Chave Pix: 28.797.532.0001/10
                      </Typography>
                      <Button
                        startIcon={<ContentCopyIcon />}
                        onClick={copyPixKey}
                        variant="outlined"
                        sx={{
                          marginTop: 1,
                          color: "#5d0707",
                          borderColor: "#5d0707",
                        }}
                      >
                        Copiar Chave Pix
                      </Button>
                      <Typography variant="body2" sx={{ marginTop: 2 }}>
                        Envie o comprovante junto com o pedido via WhatsApp.
                      </Typography>
                    </>
                  )}

                  {isSubmitting ? (
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#5d0707", marginTop: 2 }}
                      disabled
                    >
                      <CircularProgress size={24} sx={{ color: "#fff" }} />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#5d0707", marginTop: 2 }}
                      onClick={handleOrderSubmission}
                    >
                      Enviar Pedido via WhatsApp
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Modal>
        </>
      )}

      {/* Snackbar para exibir quando item é adicionado ao carrinho */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={1700}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          color: "white",
          transform: "scale(1.0)",
          backgroundColor: "#5d0707",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{
            color: "white",
            width: "60%",
            backgroundColor: "#5d0707",
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          Item adicionado ao carrinho com sucesso!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Delivery;
