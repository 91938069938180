import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import api from "../services/api";

const UsersList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("/users");
        setUsers(response.data);
      } catch (error) {
        console.error("Erro ao buscar usuários", error);
      }
    };
    fetchUsers();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "username", headerName: "Nome", width: 150 },
    { field: "phone", headerName: "Telefone", width: 150 },
    { field: "address", headerName: "Endereço", width: 200 },
  ];

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        "& .MuiDataGrid-row": {
          backgroundColor: "#f0f8ff",
          "&:nth-of-type(odd)": {
            backgroundColor: "#e6f7ff",
          },
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: "#b3e5fc",
          },
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#1976d2",
          color: "#000",
          fontSize: "16px",
          fontWeight: "bold",
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      }}
    >
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={5}
        checkboxSelection
        disableColumnMenu
      />
    </Box>
  );
};

export default UsersList;
