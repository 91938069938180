import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem, Typography, Button } from "@mui/material";
import api from "../services/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const opcoes = {
  timeZone: "America/Sao_Paulo",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [countdown, setCountdown] = useState(15);

  // Função para buscar pedidos
  const fetchOrders = async () => {
    try {
      const response = await api.get("/orders");
      const newOrders = response.data;

      const receivedOrders = newOrders.filter(
        (order) => order.status === "Recebido"
      );
      if (receivedOrders.length > 0) {
        receivedOrders.forEach((order) => {
          toast.info(
            `Você tem pedidos com status de recebido de: ${order.name}`,
            {
              position: "top-right",
              autoClose: 5000,
            }
          );
        });
      }

      setOrders(newOrders);
    } catch (error) {
      console.error("Erro ao buscar pedidos", error);
    }
  };

  useEffect(() => {
    fetchOrders();
    const interval = setInterval(() => {
      fetchOrders();
      setCountdown(15);
    }, 15000);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 15
      );
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(countdownInterval);
    };
  }, []);

  const handleStatusChange = async (id, newStatus, order) => {
    try {
      await api.put(`/orders/${id}/status`, { status: newStatus });
      setOrders((prevOrders) =>
        prevOrders.map((o) => (o.id === id ? { ...o, status: newStatus } : o))
      );

      // if (newStatus === "Em preparação") {
      //   printOrder(order); // Chama a função de impressão
      // }
    } catch (error) {
      console.error("Erro ao atualizar o status do pedido", error);
    }
  };

  // Função para imprimir o pedido
  const printOrder = (order) => {
    const printWindow = window.open("", "_blank"); // Abre uma nova janela para impressão
    printWindow.document.write(`
      <html>
        <head>
          <title>Impressão do Pedido</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
            }
            h1 {
              color: #5d0707;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            table, th, td {
              border: 1px solid #ddd;
              padding: 8px;
            }
            th {
              background-color: #5d0707;
              color: white;
            }
          </style>
        </head>
        <body>
          <h1>Pedido #${order.id}</h1>
          <p><strong>Nome:</strong> ${order.name}</p>
          <p><strong>Endereço:</strong> ${order.delivery_address}</p>
          <p><strong>Telefone:</strong> ${order.customer_id}</p>
          <h2>Itens:</h2>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Preço</th>
              </tr>
            </thead>
            <tbody>
              ${JSON.parse(order.items)
                .map(
                  (item) =>
                    `<tr>
                      <td>${item.dishName}</td>
                      <td>R$ ${item.price.toFixed(2)}</td>
                    </tr>`
                )
                .join("")}
            </tbody>
          </table>
          <p><strong>Total:</strong> R$ ${order.total_price}</p>
          <p><strong>Forma de Pagamento:</strong> ${order.paymentMethod}</p>
          ${
            order.paymentMethod === "Dinheiro" && order.change
              ? `<p><strong>Troco para:</strong> R$ ${order.change}</p>`
              : ""
          }
        </body>
      </html>
    `);

    printWindow.document.close(); // Fecha o documento para finalizar a escrita
    printWindow.focus(); // Coloca o foco na nova janela
    printWindow.print(); // Abre a janela de diálogo de impressão
    printWindow.close(); // Fecha a janela após a impressão
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Recebido":
        return "#f5e0e0";
      case "Em preparação":
        return "#FFF9C4"; // amarelo claro
      case "Entregue":
        return "#BBDEFB"; // azul clarinho
      case "Cancelado":
        return "#D3D3D3"; // Cinza clarinho
      default:
        return "#ffffff";
    }
  };

  return (
    <Box
      sx={{
        width: "calc(100% - 240px)",
        marginLeft: "240px",
        paddingTop: 2,
        paddingBottom: 4,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Typography
        sx={{
          backgroundColor: "#5d0707",
          color: "#fff",
          padding: "8px",
          borderRadius: "5px",
          textAlign: "center",
          marginBottom: 2,
          width: "80%",
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        Verificando novos pedidos em: {countdown} segundos
      </Typography>

      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
        gap={2}
      >
        {orders.map((order) => (
          <Box
            key={order.id}
            sx={{
              backgroundColor: getStatusColor(order.status),
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // Mantém consistência entre os cards
              minHeight: "250px", // Altura mínima para uniformidade
              maxHeight: "400px", // Limita o crescimento dos cards
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6">Pedido #{order.id}</Typography>
              <Typography variant="h6">
                Data:{" "}
                {new Date(order.created_at).toLocaleString("pt-BR", {
                  timeZone: "America/Sao_Paulo",
                  day: "2-digit",
                  month: "short", // Mês abreviado
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
              <Typography variant="body1">Nome: {order.name}</Typography>
              <Typography variant="body1">
                Endereço: {order.delivery_address}
              </Typography>
              <Typography variant="body1">
                Telefone: {order.customer_id}
              </Typography>

              {/* Adicionar um limite e rolagem para itens */}
              {order.items && (
                <Box
                  sx={{
                    marginTop: 1,
                    maxHeight: "150px", // Limite para evitar cards desproporcionais
                    overflowY: "auto", // Permite rolagem caso necessário
                    paddingRight: "8px", // Evita sobreposição da barra de rolagem
                  }}
                >
                  {JSON.parse(order.items).map((item, i) => (
                    <Typography key={i} variant="body2">
                      {item.dishName} - R$ {item.price.toFixed(2)}
                    </Typography>
                  ))}
                </Box>
              )}

              <Typography variant="body1">
                Total: R$ {order.total_price}
              </Typography>
              {order.paymentMethod && (
                <Typography variant="body1">
                  Pagamento via: {order.paymentMethod}
                </Typography>
              )}
              {order.paymentMethod === "Dinheiro" && order.change && (
                <Typography variant="body1">
                  Troco para: R$ {order.change}
                </Typography>
              )}
            </Box>

            {/* Dropdown de status */}
            <Box>
              <Select
                value={order.status}
                onChange={(e) =>
                  handleStatusChange(order.id, e.target.value, order)
                }
                fullWidth
                sx={{ marginTop: "auto" }}
              >
                <MenuItem value="Recebido">Recebido</MenuItem>
                <MenuItem value="Em preparação">Em preparação</MenuItem>
                <MenuItem value="Entregue">Entregue</MenuItem>
                <MenuItem value="Cancelado">Cancelado</MenuItem>
              </Select>
            </Box>
          </Box>
        ))}
      </Box>

      <Button
        variant="contained"
        sx={{ backgroundColor: "#5d0707", marginTop: 3 }}
        onClick={fetchOrders}
      >
        Carregar Mais Pedidos
      </Button>

      <ToastContainer />
    </Box>
  );
};

export default OrdersList;
