import axios from "axios";

// Configurar a URL base da API
const api = axios.create({
  baseURL: "https://cachoeiras-api.onrender.com/api", // ajuste para a URL do backend se for diferente
});

// Interceptar requisições para adicionar o token de autorização
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
